import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import freeBus from "./images/free-bus.png";
import styles from "./Contacts.module.css";

const Contacts = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        // background: "#d7d7d7",
        padding: "10px",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "500px",
          // height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          padding: "10px",
        }}
      >
        {/* <h3
          style={{
            marginTop: "15px",
          }}
        >
          This is PWA, it can be installed on any device. From the browser
          options select 'Add to home screen'
        </h3> */}{" "}
        <h5 className={styles.header} style={{ marginBottom: "30px" }}>
          About BlackJack Bot
        </h5>{" "}
        <article style={{ marginBottom: "30px" }}>
          <br />
          <p className={styles.aboutP}>
            {" "}
            The app is designed to facilitate the use of the BlackJack Basic
            Strategy.
            <br /> <br />
            Basic strategy is based upon a player's point total and the dealer's
            visible card.
            <br />
            <br />
            Each BlackJack game has a basic strategy, which prescribes the
            optimal method of playing any hand against any dealer up-card so
            that the long-term house advantage (the expected loss of the player)
            is minimized.
          </p>

          <p className={styles.aboutP}>
            <br />
            If you perfectly follow the strategies outlined in this guide, you
            can reduce the casino’s advantage to less than 0.5%, making
            BlackJack the most advantageous game to the player in a casino.
            <br /> <br />
            Perfect BlackJack strategy has been determined using probability
            theory and computer simulations which have figured out the best play
            in each situation.
          </p>
        </article>
        <hr style={{ width: "100%", marginBottom: "30px" }} />
        <article>
          <p className={styles.aboutP}>
            BlackJackBot.net is a PWA( Progressive Web App ), it can be
            installed on any device, when you choose your browser option "Add to
            home screen".
          </p>
          <h5 style={{ marginBottom: "10px" }}>For IOS Install</h5>
          <div className={styles.ios}></div>

          <h5 style={{ marginTop: "20px", marginBottom: "10px" }}>
            For Android Install
          </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className={styles.android}></div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default Contacts;
