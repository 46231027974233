export default [
  { dealer: "2", player: "5", action: "Hit" },
  { dealer: "2", player: "6", action: "Hit" },
  { dealer: "2", player: "7", action: "Hit" },
  { dealer: "2", player: "8", action: "Hit" },
  { dealer: "2", player: "9", action: "Double if allowed, otherwise Hit" },
  { dealer: "2", player: "10", action: "Double if allowed, otherwise Hit" },
  { dealer: "2", player: "11", action: "Double if allowed, otherwise Hit" },
  { dealer: "2", player: "12", action: "Hit" },
  { dealer: "2", player: "13", action: "Stand" },
  { dealer: "2", player: "14", action: "Stand" },
  { dealer: "2", player: "15", action: "Stand" },
  { dealer: "2", player: "16", action: "Stand" },
  { dealer: "2", player: "17", action: "Stand" },
  { dealer: "2", player: "18", action: "Stand" },
  { dealer: "2", player: "19", action: "Stand" },
  { dealer: "2", player: "20", action: "Stand" },
  { dealer: "2", player: "21", action: "Stand" },

  { dealer: "3", player: "5", action: "Hit" },
  { dealer: "3", player: "6", action: "Hit" },
  { dealer: "3", player: "7", action: "Hit" },
  { dealer: "3", player: "8", action: "Hit" },
  { dealer: "3", player: "9", action: "Double if allowed, otherwise Hit" },
  { dealer: "3", player: "10", action: "Double if allowed, otherwise Hit" },
  { dealer: "3", player: "11", action: "Double if allowed, otherwise Hit" },
  { dealer: "3", player: "12", action: "Hit" },
  { dealer: "3", player: "13", action: "Stand" },
  { dealer: "3", player: "14", action: "Stand" },
  { dealer: "3", player: "15", action: "Stand" },
  { dealer: "3", player: "16", action: "Stand" },
  { dealer: "3", player: "17", action: "Stand" },
  { dealer: "3", player: "18", action: "Stand" },
  { dealer: "3", player: "19", action: "Stand" },
  { dealer: "3", player: "20", action: "Stand" },
  { dealer: "3", player: "21", action: "Stand" },

  { dealer: "4", player: "5", action: "Hit" },
  { dealer: "4", player: "6", action: "Hit" },
  { dealer: "4", player: "7", action: "Hit" },
  { dealer: "4", player: "8", action: "Hit" },
  { dealer: "4", player: "9", action: "Double if allowed, otherwise Hit" },
  { dealer: "4", player: "10", action: "Double if allowed, otherwise Hit" },
  { dealer: "4", player: "11", action: "Double if allowed, otherwise Hit" },
  { dealer: "4", player: "12", action: "Stand" },
  { dealer: "4", player: "13", action: "Stand" },
  { dealer: "4", player: "14", action: "Stand" },
  { dealer: "4", player: "15", action: "Stand" },
  { dealer: "4", player: "16", action: "Stand" },
  { dealer: "4", player: "17", action: "Stand" },
  { dealer: "4", player: "18", action: "Stand" },
  { dealer: "4", player: "19", action: "Stand" },
  { dealer: "4", player: "20", action: "Stand" },
  { dealer: "4", player: "21", action: "Stand" },

  { dealer: "5", player: "5", action: "Hit" },
  { dealer: "5", player: "6", action: "Hit" },
  { dealer: "5", player: "7", action: "Hit" },
  { dealer: "5", player: "8", action: "Double if allowed, otherwise Hit" },
  { dealer: "5", player: "9", action: "Double if allowed, otherwise Hit" },
  { dealer: "5", player: "10", action: "Double if allowed, otherwise Hit" },
  { dealer: "5", player: "11", action: "Double if allowed, otherwise Hit" },
  { dealer: "5", player: "12", action: "Stand" },
  { dealer: "5", player: "13", action: "Stand" },
  { dealer: "5", player: "14", action: "Stand" },
  { dealer: "5", player: "15", action: "Stand" },
  { dealer: "5", player: "16", action: "Stand" },
  { dealer: "5", player: "17", action: "Stand" },
  { dealer: "5", player: "18", action: "Stand" },
  { dealer: "5", player: "19", action: "Stand" },
  { dealer: "5", player: "20", action: "Stand" },
  { dealer: "5", player: "21", action: "Stand" },

  { dealer: "6", player: "5", action: "Hit" },
  { dealer: "6", player: "6", action: "Hit" },
  { dealer: "6", player: "7", action: "Hit" },
  { dealer: "6", player: "8", action: "Double if allowed, otherwise Hit" },
  { dealer: "6", player: "9", action: "Double if allowed, otherwise Hit" },
  { dealer: "6", player: "10", action: "Double if allowed, otherwise Hit" },
  { dealer: "6", player: "11", action: "Double if allowed, otherwise Hit" },
  { dealer: "6", player: "12", action: "Stand" },
  { dealer: "6", player: "13", action: "Stand" },
  { dealer: "6", player: "14", action: "Stand" },
  { dealer: "6", player: "15", action: "Stand" },
  { dealer: "6", player: "16", action: "Stand" },
  { dealer: "6", player: "17", action: "Stand" },
  { dealer: "6", player: "18", action: "Stand" },
  { dealer: "6", player: "19", action: "Stand" },
  { dealer: "6", player: "20", action: "Stand" },
  { dealer: "6", player: "21", action: "Stand" },

  { dealer: "7", player: "5", action: "Hit" },
  { dealer: "7", player: "6", action: "Hit" },
  { dealer: "7", player: "7", action: "Hit" },
  { dealer: "7", player: "8", action: "Hit" },
  { dealer: "7", player: "9", action: "Hit" },
  { dealer: "7", player: "10", action: "Double if allowed, otherwise Hit" },
  { dealer: "7", player: "11", action: "Double if allowed, otherwise Hit" },
  { dealer: "7", player: "12", action: "Hit" },
  { dealer: "7", player: "13", action: "Hit" },
  { dealer: "7", player: "14", action: "Hit" },
  { dealer: "7", player: "15", action: "Hit" },
  { dealer: "7", player: "16", action: "Hit" },
  { dealer: "7", player: "17", action: "Stand" },
  { dealer: "7", player: "18", action: "Stand" },
  { dealer: "7", player: "19", action: "Stand" },
  { dealer: "7", player: "20", action: "Stand" },
  { dealer: "7", player: "21", action: "Stand" },

  { dealer: "8", player: "5", action: "Hit" },
  { dealer: "8", player: "6", action: "Hit" },
  { dealer: "8", player: "7", action: "Hit" },
  { dealer: "8", player: "8", action: "Hit" },
  { dealer: "8", player: "9", action: "Hit" },
  { dealer: "8", player: "10", action: "Double if allowed, otherwise Hit" },
  { dealer: "8", player: "11", action: "Double if allowed, otherwise Hit" },
  { dealer: "8", player: "12", action: "Hit" },
  { dealer: "8", player: "13", action: "Hit" },
  { dealer: "8", player: "14", action: "Hit" },
  { dealer: "8", player: "15", action: "Hit" },
  { dealer: "8", player: "16", action: "Hit" },
  { dealer: "8", player: "17", action: "Stand" },
  { dealer: "8", player: "18", action: "Stand" },
  { dealer: "8", player: "19", action: "Stand" },
  { dealer: "8", player: "20", action: "Stand" },
  { dealer: "8", player: "21", action: "Stand" },

  { dealer: "9", player: "5", action: "Hit" },
  { dealer: "9", player: "6", action: "Hit" },
  { dealer: "9", player: "7", action: "Hit" },
  { dealer: "9", player: "8", action: "Hit" },
  { dealer: "9", player: "9", action: "Hit" },
  { dealer: "9", player: "10", action: "Double if allowed, otherwise Hit" },
  { dealer: "9", player: "11", action: "Double if allowed, otherwise Hit" },
  { dealer: "9", player: "12", action: "Hit" },
  { dealer: "9", player: "13", action: "Hit" },
  { dealer: "9", player: "14", action: "Hit" },
  { dealer: "9", player: "15", action: "Hit" },
  { dealer: "9", player: "16", action: "Hit" },
  { dealer: "9", player: "17", action: "Stand" },
  { dealer: "9", player: "18", action: "Stand" },
  { dealer: "9", player: "19", action: "Stand" },
  { dealer: "9", player: "20", action: "Stand" },
  { dealer: "9", player: "21", action: "Stand" },

  { dealer: "10", player: "5", action: "Hit" },
  { dealer: "10", player: "6", action: "Hit" },
  { dealer: "10", player: "7", action: "Hit" },
  { dealer: "10", player: "8", action: "Hit" },
  { dealer: "10", player: "9", action: "Hit" },
  { dealer: "10", player: "10", action: "Hit" },
  { dealer: "10", player: "11", action: "Double if allowed, otherwise Hit" },
  { dealer: "10", player: "12", action: "Hit" },
  { dealer: "10", player: "13", action: "Hit" },
  { dealer: "10", player: "14", action: "Hit" },
  { dealer: "10", player: "15", action: "Hit" },
  { dealer: "10", player: "16", action: "Surrender if allowed, otherwise Hit" },
  { dealer: "10", player: "17", action: "Stand" },
  { dealer: "10", player: "18", action: "Stand" },
  { dealer: "10", player: "19", action: "Stand" },
  { dealer: "10", player: "20", action: "Stand" },
  { dealer: "10", player: "21", action: "Stand" },

  { dealer: "A", player: "5", action: "Hit" },
  { dealer: "A", player: "6", action: "Hit" },
  { dealer: "A", player: "7", action: "Hit" },
  { dealer: "A", player: "8", action: "Hit" },
  { dealer: "A", player: "9", action: "Hit" },
  { dealer: "A", player: "10", action: "Hit" },
  { dealer: "A", player: "11", action: "Double if allowed, otherwise Hit" },
  { dealer: "A", player: "12", action: "Hit" },
  { dealer: "A", player: "13", action: "Hit" },
  { dealer: "A", player: "14", action: "Hit" },
  { dealer: "A", player: "15", action: "Surrender if allowed, otherwise Hit" },
  { dealer: "A", player: "16", action: "Surrender if allowed, otherwise Hit" },
  {
    dealer: "A",
    player: "17",
    action: "Surrender if allowed, otherwise Stand",
  },
  { dealer: "A", player: "18", action: "Stand" },
  { dealer: "A", player: "19", action: "Stand" },
  { dealer: "A", player: "20", action: "Stand" },
  { dealer: "A", player: "21", action: "Stand" },

  //SOFT TOTALS
  { dealer: "2", player: "A2", action: "Hit" },
  { dealer: "2", player: "A3", action: "Hit" },
  { dealer: "2", player: "A4", action: "Hit" },
  { dealer: "2", player: "A5", action: "Hit" },
  { dealer: "2", player: "A6", action: "Double if allowed, otherwise Hit" },
  { dealer: "2", player: "A7", action: "Stand" },
  { dealer: "2", player: "A8", action: "Stand" },
  { dealer: "2", player: "A9", action: "Stand" },
  { dealer: "2", player: "A10", action: "Stand" },

  { dealer: "3", player: "A2", action: "Hit" },
  { dealer: "3", player: "A3", action: "Hit" },
  { dealer: "3", player: "A4", action: "Hit" },
  { dealer: "3", player: "A5", action: "Hit" },
  { dealer: "3", player: "A6", action: "Double if allowed, otherwise Hit" },
  { dealer: "3", player: "A7", action: "Double if allowed, otherwise Stand" },
  { dealer: "3", player: "A8", action: "Stand" },
  { dealer: "3", player: "A9", action: "Stand" },
  { dealer: "3", player: "A10", action: "Stand" },

  { dealer: "4", player: "A2", action: "Double if allowed, otherwise Hit" },
  { dealer: "4", player: "A3", action: "Double if allowed, otherwise Hit" },
  { dealer: "4", player: "A4", action: "Double if allowed, otherwise Hit" },
  { dealer: "4", player: "A5", action: "Double if allowed, otherwise Hit" },
  { dealer: "4", player: "A6", action: "Double if allowed, otherwise Hit" },
  { dealer: "4", player: "A7", action: "Double if allowed, otherwise Stand" },
  { dealer: "4", player: "A8", action: "Stand" },
  { dealer: "4", player: "A9", action: "Stand" },
  { dealer: "4", player: "A10", action: "Stand" },

  { dealer: "5", player: "A2", action: "Double if allowed, otherwise Hit" },
  { dealer: "5", player: "A3", action: "Double if allowed, otherwise Hit" },
  { dealer: "5", player: "A4", action: "Double if allowed, otherwise Hit" },
  { dealer: "5", player: "A5", action: "Double if allowed, otherwise Hit" },
  { dealer: "5", player: "A6", action: "Double if allowed, otherwise Hit" },
  { dealer: "5", player: "A7", action: "Double if allowed, otherwise Stand" },
  { dealer: "5", player: "A8", action: "Stand" },
  { dealer: "5", player: "A9", action: "Stand" },
  { dealer: "5", player: "A10", action: "Stand" },

  { dealer: "6", player: "A2", action: "Double if allowed, otherwise Hit" },
  { dealer: "6", player: "A3", action: "Double if allowed, otherwise Hit" },
  { dealer: "6", player: "A4", action: "Double if allowed, otherwise Hit" },
  { dealer: "6", player: "A5", action: "Double if allowed, otherwise Hit" },
  { dealer: "6", player: "A6", action: "Double if allowed, otherwise Hit" },
  { dealer: "6", player: "A7", action: "Double if allowed, otherwise Stand" },
  { dealer: "6", player: "A8", action: "Double if allowed, otherwise Stand" },
  { dealer: "6", player: "A9", action: "Stand" },
  { dealer: "6", player: "A10", action: "Stand" },

  { dealer: "7", player: "A2", action: "Hit" },
  { dealer: "7", player: "A3", action: "Hit" },
  { dealer: "7", player: "A4", action: "Hit" },
  { dealer: "7", player: "A5", action: "Hit" },
  { dealer: "7", player: "A6", action: "Hit" },
  { dealer: "7", player: "A7", action: "Stand" },
  { dealer: "7", player: "A8", action: "Stand" },
  { dealer: "7", player: "A9", action: "Stand" },
  { dealer: "7", player: "A10", action: "Stand" },

  { dealer: "8", player: "A2", action: "Hit" },
  { dealer: "8", player: "A3", action: "Hit" },
  { dealer: "8", player: "A4", action: "Hit" },
  { dealer: "8", player: "A5", action: "Hit" },
  { dealer: "8", player: "A6", action: "Hit" },
  { dealer: "8", player: "A7", action: "Stand" },
  { dealer: "8", player: "A8", action: "Stand" },
  { dealer: "8", player: "A9", action: "Stand" },
  { dealer: "8", player: "A10", action: "Stand" },

  { dealer: "9", player: "A2", action: "Hit" },
  { dealer: "9", player: "A3", action: "Hit" },
  { dealer: "9", player: "A4", action: "Hit" },
  { dealer: "9", player: "A5", action: "Hit" },
  { dealer: "9", player: "A6", action: "Hit" },
  { dealer: "9", player: "A7", action: "Hit" },
  { dealer: "9", player: "A8", action: "Stand" },
  { dealer: "9", player: "A9", action: "Stand" },
  { dealer: "9", player: "A10", action: "Stand" },

  { dealer: "10", player: "A2", action: "Hit" },
  { dealer: "10", player: "A3", action: "Hit" },
  { dealer: "10", player: "A4", action: "Hit" },
  { dealer: "10", player: "A5", action: "Hit" },
  { dealer: "10", player: "A6", action: "Hit" },
  { dealer: "10", player: "A7", action: "Hit" },
  { dealer: "10", player: "A8", action: "Stand" },
  { dealer: "10", player: "A9", action: "Stand" },
  { dealer: "10", player: "A10", action: "Stand" },

  { dealer: "A", player: "A2", action: "Hit" },
  { dealer: "A", player: "A3", action: "Hit" },
  { dealer: "A", player: "A4", action: "Hit" },
  { dealer: "A", player: "A5", action: "Hit" },
  { dealer: "A", player: "A6", action: "Hit" },
  { dealer: "A", player: "A7", action: "Hit" },
  { dealer: "A", player: "A8", action: "Stand" },
  { dealer: "A", player: "A9", action: "Stand" },
  { dealer: "A", player: "A10", action: "Stand" },

  //PAIR SPLIT

  {
    dealer: "2",
    player: "22",
    action: "Split if double after split is allowed, otherwise Hit",
  },
  {
    dealer: "2",
    player: "33",
    action: "Split if double after split is allowed, otherwise Hit",
  },
  { dealer: "2", player: "44", action: "Hit" },
  { dealer: "2", player: "66", action: "Split" },
  { dealer: "2", player: "77", action: "Split" },
  { dealer: "2", player: "88", action: "Split" },
  { dealer: "2", player: "99", action: "Split" },
  { dealer: "2", player: "AA", action: "Split" },

  { dealer: "3", player: "22", action: "Split" },
  {
    dealer: "3",
    player: "33",
    action: "Split if double after split is allowed, otherwise Hit",
  },
  { dealer: "3", player: "44", action: "Hit" },
  { dealer: "3", player: "66", action: "Split" },
  { dealer: "3", player: "77", action: "Split" },
  { dealer: "3", player: "88", action: "Split" },
  { dealer: "3", player: "99", action: "Split" },
  { dealer: "3", player: "AA", action: "Split" },

  { dealer: "4", player: "22", action: "Split" },
  { dealer: "4", player: "33", action: "Split" },
  {
    dealer: "4",
    player: "44",
    action: "Split if double after split is allowed, otherwise Hit",
  },
  { dealer: "4", player: "66", action: "Split" },
  { dealer: "4", player: "77", action: "Split" },
  { dealer: "4", player: "88", action: "Split" },
  { dealer: "4", player: "99", action: "Split" },
  { dealer: "4", player: "AA", action: "Split" },

  { dealer: "5", player: "22", action: "Split" },
  { dealer: "5", player: "33", action: "Split" },
  {
    dealer: "5",
    player: "44",
    action: "Split if double after split is allowed, otherwise Double",
  },
  { dealer: "5", player: "66", action: "Split" },
  { dealer: "5", player: "77", action: "Split" },
  { dealer: "5", player: "88", action: "Split" },
  { dealer: "5", player: "99", action: "Split" },
  { dealer: "5", player: "AA", action: "Split" },

  { dealer: "6", player: "22", action: "Split" },
  { dealer: "6", player: "33", action: "Split" },
  {
    dealer: "6",
    player: "44",
    action: "Split if double after split is allowed, otherwise Double",
  },
  { dealer: "6", player: "66", action: "Split" },
  { dealer: "6", player: "77", action: "Split" },
  { dealer: "6", player: "88", action: "Split" },
  { dealer: "6", player: "99", action: "Split" },
  { dealer: "6", player: "AA", action: "Split" },

  { dealer: "7", player: "22", action: "Split" },
  { dealer: "7", player: "33", action: "Split" },
  {
    dealer: "7",
    player: "44",
    action: "Hit",
  },
  {
    dealer: "7",
    player: "66",
    action: "Split if double after split is allowed, otherwise Hit",
  },
  { dealer: "7", player: "77", action: "Split" },
  { dealer: "7", player: "88", action: "Split" },
  { dealer: "7", player: "99", action: "Stand" },
  { dealer: "7", player: "AA", action: "Split" },

  { dealer: "8", player: "22", action: "Hit" },
  {
    dealer: "8",
    player: "33",
    action: "Split if double after split is allowed, otherwise Hit",
  },
  {
    dealer: "8",
    player: "44",
    action: "Hit",
  },
  { dealer: "8", player: "66", action: "Hit" },
  {
    dealer: "8",
    player: "77",
    action: "Split if double after split is allowed, otherwise Hit",
  },
  { dealer: "8", player: "88", action: "Split" },
  { dealer: "8", player: "99", action: "Split" },
  { dealer: "8", player: "AA", action: "Split" },

  { dealer: "9", player: "22", action: "Hit" },
  { dealer: "9", player: "33", action: "Hit" },
  {
    dealer: "9",
    player: "44",
    action: "Hit",
  },
  { dealer: "9", player: "66", action: "Hit" },
  { dealer: "9", player: "77", action: "Hit" },
  { dealer: "9", player: "88", action: "Split" },
  { dealer: "9", player: "99", action: "Split" },
  { dealer: "9", player: "AA", action: "Split" },

  { dealer: "10", player: "22", action: "Hit" },
  { dealer: "10", player: "33", action: "Hit" },
  {
    dealer: "10",
    player: "44",
    action: "Hit",
  },
  { dealer: "10", player: "66", action: "Hit" },
  {
    dealer: "10",
    player: "77",
    action: "Surrender if allowed, otherwise Stand",
  },
  { dealer: "10", player: "88", action: "Split" },
  { dealer: "10", player: "99", action: "Stand" },
  { dealer: "10", player: "AA", action: "Split" },

  { dealer: "A", player: "22", action: "Hit" },
  { dealer: "A", player: "33", action: "Hit" },
  {
    dealer: "A",
    player: "44",
    action: "Hit",
  },
  { dealer: "A", player: "66", action: "Hit" },
  {
    dealer: "A",
    player: "77",
    action: "Surrender if allowed, otherwise Hit",
  },
  { dealer: "A", player: "88", action: "Split" },
  {
    dealer: "A",
    player: "99",
    action: "Split if double after split is allowed, otherwise Stand",
  },
  { dealer: "A", player: "AA", action: "Split" },
];
