import React, { useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import data1dh17 from "./data/data1dh17";
import data1ds17 from "./data/data1ds17";
import data2dh17 from "./data/data2dh17";
import data2ds17 from "./data/data2ds17";
import data4dh17 from "./data/data4dh17";
import data4ds17 from "./data/data4ds17";

import "./DigiStrategy.css";
import styles from "./Home.module.css";
const DigiStrategy = () => {
  let { dataCode, message } = useParams();
  const [dealer, setDealer] = useState("");
  const [player, setPlayer] = useState("");
  const [plArray, setPlArray] = useState([]);
  const [msg, setMsg] = useState("");
  const [displayPl, setDisplayPl] = useState([]);
  const [msgColor, setMsgColor] = useState(true);
  const [stateMessage, setStateMessage] = useState("");
  const [stateData, setStateData] = useState("");

  const [redirect, setRedirect] = useState(false);

  React.useEffect(() => {
    execData();
  }, [player]);

  React.useEffect(() => {
    console.log("datacode", message);
    setStateData(dataCode);
    setStateMessage(message);
  }, []);
  const nextHand = () => {
    setDealer("");
    setPlayer("");
    setMsg("");
    setPlArray([]);
    setDisplayPl([]);
  };

  const execData = () => {
    let data;

    if (stateData === "data1dh17") data = data1dh17;
    if (stateData === "data1ds17") data = data1ds17;
    if (stateData === "data2dh17") data = data2dh17;
    if (stateData === "data2ds17") data = data2ds17;
    if (stateData === "data4dh17") data = data4dh17;
    if (stateData === "data4ds17") data = data4ds17;

    if (dealer && player) {
      let outMsg = data.find(
        (obj) => obj.dealer === dealer && obj.player === player
      ).action;

      console.log(outMsg);
      setMsg(outMsg);
      let playernew = player;
      console.log("dealer", dealer);
      console.log("player", playernew);
    }
  };
  //what to do button
  const output = async () => {
    setMsgColor((prevState) => !prevState);

    if (!dealer) {
      setMsg("Choose Dealers First Card!");
      return;
    }
    if (plArray.length < 2) {
      setMsg("Choose At least 2 Cards For You!");
      return;
    }

    //main 1
    if (plArray.length > 20) {
      setMsg("BUST");
      return;
    }
    //main 2
    if (plArray.length === 2) {
      if (plArray[0] === plArray[1]) {
        if (plArray[0] === "10" && plArray[1] === "10") {
          setPlayer("20");
          return;
        }
        if (plArray[0] === "5" && plArray[1] === "5") {
          setPlayer("10");
          return;
        }
        if (plArray[0] === "1" && plArray[1] === "1") {
          setPlayer("AA");
          return;
        }
        let cardsAdd = `${plArray[0]}${plArray[1]}`;
        setPlayer(cardsAdd);
        return;
      } else if (plArray.includes("1")) {
        if (
          (plArray[0] === "1" && plArray[1] === "10") ||
          (plArray[0] === "10" && plArray[1] === "1")
        ) {
          setMsg("Oh yes it's BlackJack");
          return;
        }
        let opit = plArray.sort();
        let cardsAdd = `A${opit[1]}`;
        setPlayer(cardsAdd);
        return;
      } else {
        let hardTotal = await plArray.reduce(function (a, b) {
          return Number(a) + Number(b);
        }, 0);
        setPlayer(`${hardTotal}`);
        console.log("sum", hardTotal);
        return;
      }
    }
    //main 3
    if (plArray.length > 2) {
      if (plArray.includes("1")) {
        let arrafterA = await [...plArray.sort().slice(1)];
        console.log("arr", arrafterA);
        let leftOver = arrafterA.reduce(function (a, b) {
          return Number(a) + Number(b);
        }, 0);
        if (leftOver < 10) {
          setPlayer(`A${leftOver}`);
          return;
        } else if (leftOver === 10) {
          setPlayer("21");
        } else if (leftOver > 10) {
          if (leftOver + 1 > 21) {
            setMsg("BUST");
          } else {
            setPlayer(`${leftOver + 1}`);
            return;
          }
        }
      } else {
        let sum = await plArray.reduce(function (a, b) {
          return Number(a) + Number(b);
        }, 0);
        if (sum > 21) {
          setMsg("BUST");
          return;
        } else {
          setPlayer(`${sum}`);
          return;
        }
      }
    }
  };

  const displayPlayerCards = (cards) => {
    let sum = plArray.reduce((a, b) => Number(a) + Number(b), 0);
    // console.log(plArray);
    if (sum > 21) {
      return <button className="displayCardButtonEmpty">BUST</button>;
    } else {
      return cards.map((card, index) => (
        <button className="displayCardButton" key={index}>
          {card}
        </button>
      ));
    }
  };

  const showPlCountBardge = () => {
    let sum = plArray.reduce((a, b) => Number(a) + Number(b), 0);
    if (sum < 12 && plArray.includes("1")) {
      return `${sum}/${sum + 10}-soft`;
    }
    if (sum > 21) return <span style={{ color: "#ff0000" }}>to many</span>;
    return sum;
  };

  if (redirect) {
    return <Navigate replace to="/" />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        // minWidth: "100%",
        minHeight: "82vh",
        alignItems: "center",
        // justifyContent: "center",
        backgroundColor: "#6c6c6c",
        maxWidth: "500px",
        margin: "0 auto",
      }}
      className="mainDiv"
    >
      {/* <div
        style={{
          width: "100px",
          height: "100px",
          backgroundColor: "#000000",
          position: "relative",
          zIndex: "0",
        }}
      ></div> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginBottom: "0",
          marginTop: "10px",
        }}
      >
        <p
          style={{
            paddingHorizontal: "10px",
            color: "#ffffff",
            marginTop: "5px",
            fontSize: "0.6rem",
          }}
          className="hide"
        >
          Insurance or even money: Don't take. Don't split 10's and 5's.
        </p>

        <p
          style={{
            paddingHorizontal: "10px",
            color: "#ffffff",
            marginTop: "5px",
            fontSize: "0.8rem",
            marginBottom: "30px",
          }}
          className="hide"
        >
          {stateMessage}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "10vh",
          // marginTop: "30px",
        }}
      >
        <p
          style={{
            fontSize: "1.5em",
            color: msgColor ? "#adffab" : "#a5e6f9",
            textAlign: "center",
            marginTop: "0",
            marginBottom: "5px",
          }}
        >
          {msg}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          maxWidth: "500px",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: 5,
          position: "absolute",
          bottom: "8vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            // marginBottom: "10px",
            // marginTop: "auto",
            shadowColor: "#000",
            shadowOffset: { width: 10, height: 10 },
            width: "100%",
            backgroundColor: "#094747",
            borderRadius: "100px 100px 0 0 ",
            borderTop: "10px solid #cdc0a7",
            borderBottom: "5px solid #cdc0a7",
            borderLeft: "5px solid #cdc0a7",
            borderRight: "5px solid #cdc0a7",
          }}
        >
          <div
            style={{
              textAlign: "center",
              color: "#ffffff",
              fontSize: "1.4rem",
            }}
          >
            <p style={{ marginTop: "5px" }}> Dealer's First Card</p> {"\n"}
            {
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <HashLink to="/contact/#ads">
                  {" "}
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: "#5c5c5c",
                      borderRadius: "50%",
                    }}
                    className={styles.logoAdd}
                  ></div>
                </HashLink>
                {dealer.length > 0 ? (
                  <button className="displayCardButtonEmpty">{dealer}</button>
                ) : (
                  <button className="displayCardButtonEmpty">D</button>
                )}
                <HashLink to="/contact/#ads">
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: "#5c5c5c",
                      borderRadius: "50%",
                    }}
                    className={styles.logoAdd}
                  ></div>
                </HashLink>
              </div>
            }
          </div>

          <p style={{ color: "#ffffff30", margin: "5px 0" }}>
            BlackJackBot.net
          </p>

          <div>
            <div
              style={{
                color: "#ffffff",
                fontSize: 24,
                marginBottom: "0",
              }}
            >
              {displayPl.length === 0 ? (
                <button className="displayCardButtonEmpty">PL</button>
              ) : displayPl.length < 20 ? (
                displayPlayerCards(displayPl)
              ) : (
                displayPlayerCards(displayPl.slice(0, 20))
              )}
              <sup>{showPlCountBardge()}</sup>
            </div>
            <div
              style={{
                textAlign: "center",
                color: "#ffffff",
                fontSize: "1.4rem",
              }}
            >
              Your Cards {"\n"}
            </div>
          </div>
        </div>{" "}
        {/* Player Buttons */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "100%",
            // marginTop: "auto",
            backgroundColor: "#0f4c75",
            padding: 0,
            margin: 0,
          }}
        >
          <p style={{ color: "#fff" }}>
            {" "}
            {dealer.length === 0
              ? "PICK DEALER'S FIRST CARD"
              : "PLAYER'S CARDS (max 20)"}{" "}
          </p>
          <div>
            <button
              className="cardButton"
              onClick={() => {
                dealer.length === 0
                  ? setDealer("A")
                  : setPlArray([...plArray, "1"]);
                dealer.length === 0
                  ? setDealer("A")
                  : setDisplayPl([...displayPl, "A"]);
              }}
            >
              <p>A</p>
            </button>
            <button
              className="cardButton"
              onClick={() => {
                dealer.length === 0
                  ? setDealer("2")
                  : setPlArray([...plArray, "2"]);
                dealer.length === 0
                  ? setDealer("2")
                  : setDisplayPl([...displayPl, "2"]);
              }}
            >
              <p>2</p>
            </button>
            <button
              className="cardButton"
              onClick={() => {
                dealer.length === 0
                  ? setDealer("3")
                  : setPlArray([...plArray, "3"]);
                dealer.length === 0
                  ? setDealer("3")
                  : setDisplayPl([...displayPl, "3"]);
              }}
            >
              <p>3</p>
            </button>
            <button
              className="cardButton"
              onClick={() => {
                dealer.length === 0
                  ? setDealer("4")
                  : setPlArray([...plArray, "4"]);
                dealer.length === 0
                  ? setDealer("4")
                  : setDisplayPl([...displayPl, "4"]);
              }}
            >
              <p>4</p>
            </button>
            <button
              className="cardButton"
              onClick={() => {
                dealer.length === 0
                  ? setDealer("5")
                  : setPlArray([...plArray, "5"]);
                dealer.length === 0
                  ? setDealer("5")
                  : setDisplayPl([...displayPl, "5"]);
              }}
            >
              <p>5</p>
            </button>
          </div>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              marginBottom: 1,
              marginTop: 1,
            }}
          >
            <button
              className="cardButton"
              onClick={() => {
                dealer.length === 0
                  ? setDealer("6")
                  : setPlArray([...plArray, "6"]);
                dealer.length === 0
                  ? setDealer("6")
                  : setDisplayPl([...displayPl, "6"]);
              }}
            >
              <p>6</p>
            </button>
            <button
              className="cardButton"
              onClick={() => {
                dealer.length === 0
                  ? setDealer("7")
                  : setPlArray([...plArray, "7"]);
                dealer.length === 0
                  ? setDealer("7")
                  : setDisplayPl([...displayPl, "7"]);
              }}
            >
              <p>7</p>
            </button>
            <button
              className="cardButton"
              onClick={() => {
                dealer.length === 0
                  ? setDealer("8")
                  : setPlArray([...plArray, "8"]);
                dealer.length === 0
                  ? setDealer("8")
                  : setDisplayPl([...displayPl, "8"]);
              }}
            >
              <p>8</p>
            </button>
            <button
              className="cardButton"
              onClick={() => {
                dealer.length === 0
                  ? setDealer("9")
                  : setPlArray([...plArray, "9"]);
                dealer.length === 0
                  ? setDealer("9")
                  : setDisplayPl([...displayPl, "9"]);
              }}
            >
              <p>9</p>
            </button>
            <button
              className="cardButton"
              onClick={() => {
                dealer.length === 0
                  ? setDealer("10")
                  : setPlArray([...plArray, "10"]);
                dealer.length === 0
                  ? setDealer("10")
                  : setDisplayPl([...displayPl, "10"]);
              }}
            >
              <div>
                {" "}
                <span style={{ fontSize: "0.6rem" }}>10,J,Q,K</span>
              </div>
            </button>
          </div>
        </div>
        {/* Players buttons */}
        <div
          style={{
            display: "flex",
            width: "100%",
            maxWidth: "500px",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            // marginTop: 5,
            backgroundColor: "#bbe1fa",
          }}
        >
          <Link
            to="#"
            id="clear"
            onClick={() => {
              nextHand();
            }}
          >
            <p
              style={{
                color: "#ffffff",
                fontWeight: "bold",
                fontSize: "1.3rem",
              }}
            >
              Clear Hand
            </p>
          </Link>
          <Link
            to="#"
            id="what"
            onClick={() => {
              output();
            }}
          >
            <p
              style={{
                color: "#ffffff",
                fontWeight: "bold",
                fontSize: "1.3rem",
              }}
            >
              What To Do?
            </p>
          </Link>
        </div>
      </div>
      <HashLink
        to="/contact/#ads"
        style={{
          display: "flex",
          position: "absolute",
          bottom: "0",
          width: "320px",
          height: "50px",
          backgroundColor: "#5c5c5c",
        }}
        className={styles.ads}
      ></HashLink>
    </div>
  );
};

export default DigiStrategy;
