import React, { useState } from "react";
import { Link } from "react-router-dom";
import plLogo from "./assets/adaptive-icon.png";
import "./Menu.css";
const Menu = () => {
  const [check, setCheck] = useState(false);
  const handleChange = () => setCheck(!check);
  return (
    <nav style={{ height: "10vh" }}>
      <div className="navbar">
        <div className="container nav-container">
          <input
            className="checkbox"
            type="checkbox"
            name=""
            id=""
            checked={check}
            onClick={() => handleChange()}
            readOnly
          />
          <div className="hamburger-lines">
            <span className="line line1"></span>
            <span className="line line2"></span>
            <span className="line line3"></span>
          </div>
          <div className="logo">
            <Link to="/">
              <img src={plLogo} style={{ height: "60px" }} alt="logo" />
            </Link>
          </div>
          <div className="menu-items">
            <li>
              <Link to="/" onClick={() => handleChange()}>
                Home
              </Link>
            </li>

            <li>
              <Link to="/strategy-card" onClick={() => handleChange()}>
                Strategy Cards
              </Link>
            </li>

            <li>
              <Link to="/info" onClick={() => handleChange()}>
                Info
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={() => handleChange()}>
                Contacts
              </Link>
            </li>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Menu;
