import "./App.css";
import DigiStrategy from "./DigiStrategy";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import MainRouter from "./MainRouter";
function App() {
  return (
    <>
      <Router>
        <MainRouter />
      </Router>
    </>
  );
}

export default App;
