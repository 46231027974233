import React, { useState } from "react";
import styles from "./Home.module.css";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import LogoBlack from "./mainlogo5.png";
import { createTheme } from "@mui/material/styles";
import { HashLink } from "react-router-hash-link";
const Home = () => {
  return (
    <>
      <div
        style={{
          // display: "flex",
          // flexDirection: "column",
          height: "90vh",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          paddingBottom: "10px",
          paddingTop: "10px",
          paddingLeft: "20px",
          paddingRight: "20px",
          width: "100%",
          maxWidth: "500px",
          margin: "0 auto",
          background: "linear-gradient(#fff, #10142e)",
        }}
      >
        <p
          style={{
            // textShadow: "1px 1px 2px #000000",
            color: "#7f7a7a",
            letterSpacing: "0px",
            fontSize: "1.5rem",
            marginTop: "10px",
            fontWeight: "500",
          }}
        >
          Welcome To BlackJack Bot
        </p>
        <p
          style={{
            paddingHorizontal: "10px",
            color: "#7f7a7a",
            fontSize: "1rem",
            marginTop: "10px",
            fontWeight: "500",
            marginBottom: "15px",
          }}
        >
          Automated BlackJack Basic Strategy
        </p>
        <p
          style={{
            paddingHorizontal: "10px",
            color: "#5c5c5c",
            marginTop: "5px",
            fontSize: "0.6rem",
          }}
        >
          Choose a strategy that meets the rules in the Casino you are in. You
          can check all strategy cards in "Strategy Cards" section.
        </p>
        <br />
        <div className={styles.cardscontainer}>
          <div className={styles.flexrowcontainer}>
            <Link
              to="/basic-card/data1dh17/1 Deck Dealer Hit on Soft 17"
              className={styles.flexrowitem}
            >
              <div className={styles.linkCard}>
                {" "}
                1 Deck <br /> Dealer Hit <br />
                on Soft 17
              </div>
            </Link>
            <Link
              to="/basic-card/data1ds17/1 Deck Dealer Stand on Soft 17"
              className={styles.flexrowitem}
            >
              <div className={styles.linkCard}>
                1 Deck <br /> Dealer Stand <br />
                on Soft 17
              </div>{" "}
            </Link>
            <Link
              to="/basic-card/data2dh17/2 Decks Dealer Hit on Soft 17"
              className={styles.flexrowitem}
            >
              <div className={styles.linkCard}>
                2 Decks <br /> Dealer Hit <br />
                on Soft 17
              </div>{" "}
            </Link>
            <Link
              to="/basic-card/data2ds17/2 Decks Dealer Stand on Soft 17"
              className={styles.flexrowitem}
            >
              <div className={styles.linkCard}>
                2 Decks <br /> Dealer Stand <br />
                on Soft 17
              </div>{" "}
            </Link>
            <Link
              to="/basic-card/data4dh17/4-6-8 Decks Dealer Hit on Soft 17"
              className={styles.flexrowitem}
            >
              <div className={styles.linkCard}>
                4-6-8 Decks <br /> Dealer Hit <br />
                on Soft 17
              </div>{" "}
            </Link>
            <Link
              to="/basic-card/data4ds17/4-6-8 Decks Dealer Stand on Soft 17"
              className={styles.flexrowitem}
            >
              <div className={styles.linkCard}>
                4-6-8 Decks <br /> Dealer Stand <br />
                on Soft 17
              </div>{" "}
            </Link>
          </div>

          <HashLink
            to="/contact/#ads"
            style={{
              display: "flex",
              position: "absolute",
              bottom: "0",
              width: "320px",
              height: "50px",
              backgroundColor: "#5c5c5c",
            }}
            className={styles.ads}
          ></HashLink>
        </div>
      </div>
    </>
  );
};

export default Home;
