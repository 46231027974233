import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import styles from "./InfoContacts.module.css";
const ContactsInfo = () => {
  return (
    <>
      {" "}
      <div
        style={{
          width: "100%",
          minHeightheight: "90vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          // background: "#d7d7d7",
          padding: "10px",
        }}
      >
        <div className={styles.container}>
          <h5 className={styles.header} style={{ marginBottom: "30px" }}>
            Contacts
          </h5>
          <article style={{ width: "90%" }}>
            <p
              style={{
                // display: "flex",
                // flexDirection: "column",
                // justifyContent: "center",
                // alignItems: "center",
                // textAlign: "center",
                marginBottom: "30px",
                color: "#505050",
                lineHeight: "1.7rem",
              }}
              className={styles.aboutP}
            >
              The app is completely free. If you have suggestions or questions,{" "}
              <br />
              if you want to advertise or have the app branded with your
              company, write to us by pressing the email icon:
            </p>

            <a
              href="mailto:blackjackbot.net@gmail.com"
              style={{
                textDecoration: "none",
                color: "#40a787",
              }}
            >
              <FontAwesomeIcon icon={faMailBulk} size="4x" />
            </a>
            <p
              style={{
                marginTop: "30px",
                marginBottom: "30px",
                color: "#505050",
              }}
            >
              www.BlackJackBot.net
            </p>
          </article>
          <article style={{ width: "90%" }} id="ads">
            <p
              className={styles.header}
              style={{ marginBottom: "30px", marginTop: "0" }}
            >
              Advertisement prices
            </p>
            <p className={styles.aboutP} style={{ lineHeight: "1.7rem" }}>
              We have two banners, each ad unit with the dimensions of 320
              pixels wide by 50 pixels tall. One on the Home page and one on the
              Bot page.
              <br /> We have two logo circles at the Blackjack table on Bot
              page.
            </p>
            <p className={styles.aboutP} style={{ lineHeight: "1.7rem" }}>
              {" "}
              Branding of all four ad units per one month: <br />{" "}
              <span style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <span style={{ marginRight: "10px" }}> 1000 USDT</span>{" "}
                <div className={styles.usdt}></div>
              </span>
              <p style={{ fontSize: "0.6rem" }}>*discount for 3 months 25%</p>
            </p>
            <p
              className={styles.header}
              style={{
                marginBottom: "30px",
                marginTop: "30px",
                fontSize: "1rem",
              }}
            >
              We accept the following cryptocurrencies:
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginBottom: "30px",
              }}
            >
              {" "}
              <div className={styles.btc}></div>
              <div className={styles.usdt}></div>
              <div className={styles.kas}></div>
              <div className={styles.eth}></div>
              <div className={styles.doge}></div>
              <div className={styles.usdc}></div>
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default ContactsInfo;
