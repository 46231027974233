import React from "react";
import card1 from "./assets/d1h17c.png";
import card2 from "./assets/d1s17c.png";
import card3 from "./assets/d2h17c.png";
import card4 from "./assets/d2s17c.png";
import card5 from "./assets/d4h17c.png";
import card6 from "./assets/d4s17c.png";
import styles from "./Contacts.module.css";
const StrategyCard = () => {
  return (
    <div
      style={{
        display: "grid",
        height: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h5 className={styles.header}>Strategy Cards</h5>
      </div>

      <img
        src={card1}
        style={{
          maxWidth: "80%",
          maxHeight: "100vh",
          margin: "auto",
          marginBottom: "20px",
          marginTop: "20px",
        }}
        alt="card"
      />
      <hr />
      <img
        src={card2}
        style={{
          maxWidth: "80%",
          maxHeight: "100vh",
          margin: "auto",
          marginBottom: "20px",
          marginTop: "20px",
        }}
        alt="card"
      />
      <hr />
      <img
        src={card3}
        style={{
          maxWidth: "80%",
          maxHeight: "100vh",
          margin: "auto",
          marginBottom: "20px",
          marginTop: "20px",
        }}
        alt="card"
      />
      <hr />
      <img
        src={card4}
        style={{
          maxWidth: "80%",
          maxHeight: "100vh",
          margin: "auto",
          marginBottom: "20px",
          marginTop: "20px",
        }}
        alt="card"
      />
      <hr />
      <img
        src={card5}
        style={{
          maxWidth: "80%",
          maxHeight: "100vh",
          margin: "auto",
          marginBottom: "20px",
          marginTop: "20px",
        }}
        alt="card"
      />
      <hr />
      <img
        src={card6}
        style={{
          maxWidth: "80%",
          maxHeight: "100vh",
          margin: "auto",
          marginBottom: "20px",
          marginTop: "20px",
        }}
        alt="card"
      />
    </div>
  );
};

export default StrategyCard;
