import React from "react";
import { Routes, Route } from "react-router-dom";
import DigiStrategy from "./DigiStrategy";
import Home from "./Home";
import Menu from "./Menu";
import StrategyCard from "./StrategyCard";
import Contacts from "./Contacts";
import ContactsInfo from "./InfoContacts";

const MainRouter = () => {
  return (
    <div>
      <Menu />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/basic-card/:dataCode/:message"
          element={<DigiStrategy />}
        />
        <Route path="/strategy-card" element={<StrategyCard />} />
        <Route path="/info" element={<Contacts />} />
        <Route path="/contact" element={<ContactsInfo />} />
      </Routes>
    </div>
  );
};

export default MainRouter;
